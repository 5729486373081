import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { initialize } from "redux-form";
import { retornarApenasNumerosString } from "../utils/retornarApenasNumerosString";

export function setModoTela(modo, registro = {}) {
	return {
		type: "USUARIO_MODO_TELA",
		payload: {
			modo: modo,
			registro: registro,
		},
	};
}
export function initForm(registro = {}) {
	return [initialize("usuarioForm", registro)];
}

export function getLista() {
	const request = axios.get(`${consts.API_URL}/admin/usuario`);
	return {
		type: "USUARIO_LISTADO",
		payload: request,
	};
}

export function salvar(registro) {
	return async (dispatch, getState) => {
		try {
			const registroFormatado = { ...registro };
			registroFormatado.cpf = retornarApenasNumerosString(registro.cpf || "");
			registroFormatado.telefone = retornarApenasNumerosString(registro.telefone || "");

			if (!registro.id) {
				await axios.post(`${consts.API_URL}/usuario`, {
					...registroFormatado,
					termo_uso: true,
					administrador: true,
				});
			} else {
				await axios.put(`${consts.API_URL}/usuario`, {
					...registroFormatado,
				});
			}
			setSuccess("Operação Realizada com sucesso.");
			dispatch(getLista());
			dispatch(setModoTela("lista"));
		} catch (erro) {
			setErroAPI(erro);
		}
	};
}

export function excluir(registro) {
	return (dispatch) => {
		axios
			.delete(`${consts.API_URL}/usuario?id=${registro.id}`)
			.then((resp) => {
				setSuccess("Operação Realizada com sucesso.");
				dispatch(getLista());
				dispatch(setModoTela("lista"));
			})
			.catch((e) => {
				setErroAPI(e);
			});
	};
}
