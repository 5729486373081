import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputMask from "../common/form/labelAndInputMask";

import { setModoTela } from "./usuarioActions";

class UsuarioForm extends Component {
  componentWillMount() {}

  render() {
    let readOnly = this.props.excluir ? "readOnly" : "";
    return (
      <ContentCard>
        <Form event={this.props.handleSubmit}>
          <ContentCardBody>
            <Field
              name="nome"
              component={LabelAndInput}
              label="Nome *"
              placeholder="Informe o Nome"
              cols="12 12 12 12"
              readOnly={readOnly}
            />

            <Field
              name="cpf"
              component={LabelAndInputMask}
              mask="999.999.999-99"
              label="CPF *"
              placeholder="Informe o CPF *"
              cols="12 12 12 12"
              readOnly={readOnly}
            />

            <Field
              name="telefone"
              component={LabelAndInputMask}
              mask="(99)99999-9999"
              label="Telefone"
              placeholder="Informe o Telefone"
              cols="12 12 12 12"
              readOnly={readOnly}
            />

            <Field
              name="email"
              component={LabelAndInput}
              label="Email *"
              placeholder="Informe o Email"
              cols="12 12 12 12"
              readOnly={readOnly}
            />

            {!this.props.formularioValues || !this.props.formularioValues.id ? (
              <Field
                name="senha"
                component={LabelAndInput}
                type="password"
                label="Senha *"
                placeholder="Informe a Senha"
                cols="12 12 12 12"
                readOnly={readOnly}
              />
            ) : null}

            {!this.props.formularioValues || !this.props.formularioValues.id ? (
              <Field
                name="senha_confirmacao"
                component={LabelAndInput}
                type="password"
                label="Confirme sua Senha *"
                placeholder="Confirme sua Senha"
                cols="12 12 12 12"
                readOnly={readOnly}
              />
            ) : null}

          </ContentCardBody>
          <ContentCardFooter>
            <Row alignCenter>
              <Grid cols="6 6 4 3">
                {this.props.excluir ? (
                  <Button
                    text="Excluir"
                    submit
                    type={"danger"}
                    icon={"fa fa-trash"}
                  />
                ) : (
                  <Button
                    text="Salvar"
                    submit
                    type={"success"}
                    icon={"fa fa-check"}
                  />
                )}
              </Grid>
              <Grid cols="6 6 4 3">
                <Button
                  text="Voltar"
                  type={"warning"}
                  icon={"fa fa-chevron-left"}
                  event={() => this.props.setModoTela("lista")}
                />
              </Grid>
            </Row>
          </ContentCardFooter>
        </Form>
      </ContentCard>
    );
  }
}

UsuarioForm = reduxForm({ form: "usuarioForm", destroyOnUnmount: false })(
  UsuarioForm
);
const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  formularioValues: getFormValues("usuarioForm")(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setModoTela }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioForm);
